import './add-payment-card.modal.scss';

export default function AddPaymentCardModal($scope, close, RecaptchaService, ToggleLD, Notification, $t) {
  'ngInject';

  $scope.card = {};

  $scope.ok = (form) => {
    if (form.$valid) {
      if (!$scope.card.policy) {
        Notification.error($t('shared.modals.add_payment_card.payment_process_required_error'));
        return;
      }

      ToggleLD.show();

      const cardAddRequest = {
        number: $scope.card.number,
        cvc: $scope.card.cvc,
        name: $scope.card.fullName,
        expiryDate: '20' + '' + $scope.card.expiry.substr(5, 2) + '-' + $scope.card.expiry.substr(0, 2),
        provider: 'Stripe',
        address: {
          postalCode: $scope.card.zip
        },
        metadata: {}
      };

      RecaptchaService.execute('save_payment_source',
        token => {
          cardAddRequest['g-recaptcha-response'] = token;
          cardAddRequest['g-recaptcha-action'] = 'save_payment_source';
          close(cardAddRequest);
        });
    }
  }

  $scope.close = () => {
    close(false);
  }
}