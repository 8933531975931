import app from "js/legacy-app";

app.config(['$routeSegmentProvider', '$routeProvider',
    function ($routeSegmentProvider, $routeProvider) {

        $routeSegmentProvider
            .when('/mechanics', 'list-mechanics')

            .segment('list-mechanics', {
                templateUrl: 'components/mechanic-list/mechanic-list.html',
                controller: 'MechanicListController',
                access: {requireLogin: false}
            });

        $routeSegmentProvider
            .when('/mechanics/:state/:city', 'mechanics-by-city')
            .segment('mechanics-by-city', {
                templateUrl: 'components/landing/mechanics-by-city/lp-mechanics-by-city.html',
                controller: 'LpMechanicsByCityController',
                access: {requireLogin: false}
            });

        $routeSegmentProvider
            .when('/not-covered-location', 'no-coverage')
            .segment('no-coverage', {
                templateUrl: 'components/landing/not-covered-location/not-covered-location.html',
                controller: 'NotCoveredLocationController',
                access: {requireLogin: false}
            });

        // Setting routes
        $routeSegmentProvider
            .when('/work-area', 'work-area-list')
            .when('/work-area/:id', 'edit-work-area')
            .segment('work-area-list', {
                templateUrl: 'components/mechanic-work-area/mechanic-work-area-list.html',
                controller: 'MechanicWorkAreaListController'
            }).segment('edit-work-area', {
                templateUrl: 'components/mechanic-work-area/edit-mechanic-work-area.html',
                controller: 'EditMechanicWorkAreaController'
            });


        $routeSegmentProvider
            .when('/settings', 'settings')
            .when('/settings/security', 'settings.security')
            .when('/settings/payment', 'settings.payment')
            .when('/settings/insurance', 'settings.insurance')
            .when('/settings/tools', 'settings.tools')

            .segment('settings', {
                templateUrl: 'template/settings/settings.html',
                controller: 'SettingsCtrl'
            })

            .within().segment('security', {
                templateUrl: 'template/settings/tabs/security.html',
                controller: 'SecuritySettingsCtrl'
            }).segment('payment', {
                templateUrl: 'template/settings/tabs/profile-payment.html',
                controller: 'PaymentSettingsCtrl'
            }).segment('insurance', {
                templateUrl: 'template/settings/tabs/profile-insurance.html',
                controller: 'InsuranceSettingsCtrl'
            }).segment('tools', {
                templateUrl: 'template/settings/tabs/profile-tools.html',
                controller: 'ToolsSettingsCtrl'
            });

        $routeSegmentProvider
            .when('/profile', 'profile')
            .when('/profile/change', 'profile.change')
            .when('/profile/manage-locations', 'profile.manage-locations')
            .when('/profile/reviews', 'profile.review')
            .when('/profile/biography', 'profile.biography')
            .when('/profile/gallery', 'profile.gallery')
            .when('/profile/company', 'profile.company')
            .when('/profile/organisation', 'profile.organisation')

            .segment('profile', {
                templateUrl: 'template/profile/profile.html',
                controller: 'ProfileCtrl'
            })
            .within()
            .segment('change', {
                templateUrl: 'template/profile/tabs/change-profile.html',
                controller: 'ChangeProfileCtrl'
            })
            .segment('manage-locations', {
                templateUrl: 'template/profile/address/profile-address-list.html',
                controller: 'ProfileAddressListCtrl'
            })
            .segment('review', {
                templateUrl: 'template/profile/tabs/profile-review.html',
                controller: 'ReviewProfileCtrl'
            })
            .segment('biography', {
                templateUrl: 'template/profile/tabs/profile-biography.html',
                controller: 'BiographyProfileCtrl'
            })
            .segment('gallery', {
                templateUrl: 'template/profile/tabs/profile-gallery.html',
                controller: 'GalleryProfileCtrl'
            })
            .segment('company', {
                templateUrl: 'template/profile/tabs/profile-company.html',
                controller: 'CompanyProfileCtrl'
            })
            .segment('organisation', {
                templateUrl: 'template/profile/tabs/profile-organisation.html',
                controller: 'OrganisationProfileCtrl'
            });

        $routeSegmentProvider
            .when('/complete-creation-request', 'complete-creation-request')

            .segment('complete-creation-request', {
                templateUrl: 'components/request/create/complete-creation-request.html',
                controller: 'CompleteCreationRequestCtrl'
            });

        $routeSegmentProvider
            .when('/add-organisation', 'add-organisation')

            .segment('add-organisation', {
                templateUrl: 'template/dashboard/bike-business-supervisor/organisation/create-organisation.html',
                controller: 'CreateOrganisationCtrl'
            });

        $routeSegmentProvider
            .when('/bike-info/:bikeId', 'bike-info')

            .segment('bike-info', {
                templateUrl: 'template/dashboard/bikes/bike-info.html',
                controller: 'BikeInfoCtrl',
                access: {requireLogin: false}
            });

        /*$routeSegmentProvider
            .when('/organisation', 'organisation')
            .when('/organisation/:organisationId/bike/:bikeId', 'organisation.bike.view')
            .when('/organisation/:organisationId/bike/:bikeId/add-owner', 'organisation.bike.add-owner')
            .when('/organisation/:organisationId/add-bike', 'organisation.bike.add')
            .when('/organisation/:organisationId/edit', 'organisation.edit')
            .when('/organisation/:organisationId/bike-groups', 'organisation.groups')
            .when('/organisation/:organisationId/requests', 'organisation.requests')
            .when('/organisation/:organisationId/requests-history', 'organisation.requests-history')
            .when('/organisation/:organisationId/bikes', 'organisation.bikes')
            .when('/organisation/:organisationId/station/add', 'organisation.station.add')
            .when('/organisation/:organisationId/station/:stationId/edit', 'organisation.station.edit')
            .when('/organisation/:organisationId/station/:stationId/add-bike', 'organisation.station.bike.add')
            .when('/organisation/:organisationId/station/:stationId/bike/:bikeId', 'organisation.station.bike.view')
            .when('/organisation/:organisationId/station/:stationId/bike/:bikeId/add-owner', 'organisation.station.bike.add-owner')

            .segment('organisation', {
                templateUrl: 'template/dashboard/bike-business-supervisor/organisation/organisation.html'
            })
            .within()
            .segment('edit', {
                templateUrl: 'template/dashboard/bike-business-supervisor/organisation/edit-organisation.html',
                controller: 'EditOrganisationCtrl'
            })
            .segment('groups', {
                templateUrl: 'template/dashboard/bike-business-supervisor/organisation/organization-bike-groups.html',
                controller: 'ViewOrganisationCtrl'
            })
            .segment('requests', {
                templateUrl: 'template/dashboard/bike-business-supervisor/organisation/organisation-requests.html',
                controller: 'ShowOrganisationRequestsCtrl'
            })
            .segment('requests-history', {
                templateUrl: 'template/dashboard/bike-business-supervisor/organisation/organisation-requests-history.html',
                controller: 'ShowOrganisationRequestsHistoryCtrl'
            })
            .segment('bikes', {
                templateUrl: 'components/bike/list/business/supervisor-bike-list.html',
                controller: 'BusinessBikeListController'
            })
            .segment('bike', {
                templateUrl: 'template/dashboard/bike-business-supervisor/organisation/bike.html'
            })
            .within()
            .segment('view', {
                templateUrl: 'template/dashboard/bike-business-supervisor/organisation/view-organisation-bike.html',
                controller: 'ViewBikeCtrl'
            })
            .segment('add', {
                templateUrl: 'template/dashboard/bikes/add-bike.html',
                controller: 'CreateBikeCtrl'
            })
            .segment('add-owner', {
                templateUrl: 'template/dashboard/bikes/show-bike/add-bike-owner.html',
                controller: 'AddBikeOwnerCtrl'
            })
            .up()
            .segment('station', {
                templateUrl: 'template/dashboard/bike-business-supervisor/organisation/station/station.html'
            })
            .within()
            .segment('add', {
                templateUrl: 'template/dashboard/bike-business-supervisor/organisation/station/create-station.html',
                controller: 'CreateStationCtrl'
            })
            .segment('edit', {
                templateUrl: 'template/dashboard/bike-business-supervisor/organisation/station/edit-station.html',
                controller: 'EditStationCtrl'
            })
            .segment('bike', {
                templateUrl: 'template/dashboard/bike-business-supervisor/organisation/station/bike.html'
            })
            .within()
            .segment('add', {
                templateUrl: 'template/dashboard/bikes/add-bike.html',
                controller: 'CreateBikeCtrl'
            })
            .segment('view', {
                templateUrl: 'template/dashboard/bike-business-supervisor/organisation/station/view-station-bike.html',
                controller: 'ViewBikeCtrl'
            })
            .segment('add-owner', {
                templateUrl: 'template/dashboard/bikes/show-bike/add-bike-owner.html',
                controller: 'AddBikeOwnerCtrl'
            })
            .up()
            .up()
            .up();*/

        $routeSegmentProvider
            .when('/dashboard', 'dashboard')
            .when('/dashboard/requests', 'dashboard.requests')
            .when('/dashboard/bicycles', 'dashboard.bicycles')
            .when('/dashboard/request-history', 'dashboard.request-history')
            .when('/dashboard/search-request', 'dashboard.search-request')
            .when('/dashboard/admin', 'dashboard.admin')
            .when('/dashboard/agreement', 'dashboard.agreement')
            .when('/dashboard/stations', 'dashboard.stations')
            .when('/dashboard/organisations', 'dashboard.organisations')

            .segment('dashboard', {
                templateUrl: 'template/dashboard/dashboard.html',
                controller: 'DashboardCtrl'
            })
            .within()
            .segment('requests', {
                templateUrl: 'components/request/list/requests.html'
            })
            .segment('bicycles', {
                templateUrl: 'components/bike/list/base/user-bike-list.html'
            })
            .segment('request-history', {
                templateUrl: 'components/request/list/request-history.html'
            })
            .segment('search-request', {
                templateUrl: 'components/request/search/mechanic-search-request.html'
            })
            .segment('admin', {
                templateUrl: 'template/admin/admin-dashboard.html'
            })
            .segment('agreement', {
                templateUrl: 'template/dashboard/mechanic/agreement.html'
            })
            .segment('stations', {
                templateUrl: 'template/dashboard/bike-organization-owner/stations.html'
            })
            .segment('organisations', {
                templateUrl: 'template/dashboard/bike-business-supervisor/dashboard-business-supervisor.html'
            });

        $routeSegmentProvider
            .when('/company-dashboard', 'company-dashboard')
            .when('/company-dashboard/bicycles', 'company-dashboard.bicycles')
            .when('/company-dashboard/request-history', 'company-dashboard.request-history')

            .segment('company-dashboard', {
                templateUrl: 'template/dashboard/dashboard.html',
            })
            .within()
            .segment('bicycles', {
                templateUrl: 'components/bike/list/business/organisation-bike-list.html',
                controller: 'BusinessBikeListController'
            })
            .segment('request-history', {
                templateUrl: 'template/dashboard/bike-organization-owner/organisation-requests-history.html',
                controller: 'ShowOrganisationRequestsHistoryCtrl'
            });

        $routeSegmentProvider
            .when('/add-bike', 'add-bike')

            .segment('add-bike', {
                templateUrl: 'template/dashboard/bikes/add-bike.html',
                controller: 'CreateBikeCtrl',
                access: {requireLogin: false}
            });

        $routeSegmentProvider
            .when('/bike', 'bike')
            .when('/bike/:bikeId', 'bike.show')
            .when('/bike/:bikeId/transfer', 'bike.transfer')

            .segment('bike', {
                templateUrl: 'template/dashboard/bikes/show-bike/bike.html'
            })
            .within()
            .segment('show', {
                templateUrl: 'template/dashboard/bikes/show-bike/view-bike.html',
                controller: 'ViewBikeCtrl'
            })
            .segment('transfer', {
                templateUrl: 'template/dashboard/bikes/transferring/transfer.html',
                controller: 'TransferBikeController'
            });

        $routeSegmentProvider
            .when('/bike/public/:bikeId', 'public-bike-profile')

            .segment('public-bike-profile', {
                templateUrl: 'template/dashboard/bikes/show-public-bike-profile.html',
                access: {requireLogin: false}
            });

        $routeSegmentProvider
            .when('/admin/bike-types', 'admin-bike-types')
            .when('/admin/bike-types/create', 'admin-bike-types-create')
            .when('/admin/bike-types/:id', 'admin-bike-types-view')

            .segment('admin-bike-types', {
                templateUrl: 'template/admin/type-of-bikes/type-of-bikes.html',
                controller: 'TypeOfBikesCtrl'
            })
            .segment('admin-bike-types-create', {
                templateUrl: 'template/admin/type-of-bikes/add-type-of-bikes.html',
                controller: 'AddTypeOfBikesCtrl'
            })
            .segment('admin-bike-types-view', {
                templateUrl: 'template/admin/type-of-bikes/add-type-of-bikes.html',
                controller: 'AddTypeOfBikesCtrl'
            });

        $routeSegmentProvider
            .when('/admin/manage-quiz', 'admin-manage-quiz')
            .when('/admin/quiz/create', 'admin-create-quiz')
            .when('/admin/quiz/:id', 'admin-edit-quiz')

            .segment('admin-manage-quiz', {
                templateUrl: 'template/admin/manage-quiz/manage-quiz.html',
                controller: 'ManageQuizCtrl'
            })
            .segment('admin-create-quiz', {
                templateUrl: 'template/admin/manage-quiz/add-quiz.html',
                controller: 'AddQuizCtrl'
            })
            .segment('admin-edit-quiz', {
                templateUrl: 'template/admin/manage-quiz/add-quiz.html',
                controller: 'AddQuizCtrl'
            });

        $routeSegmentProvider
            .when('/admin/users', 'admin-users')
            .when('/admin/create-user', 'admin-user-create')

            .segment('admin-users', {
                templateUrl: 'template/admin/users/users.html',
                controller: 'UsersCtrl'
            })
            .segment('admin-user-create', {
                templateUrl: 'template/admin/users/create-user.html',
                controller: 'CreateUserCtrl'
            });

        $routeSegmentProvider
            .when('/add-card', 'add-card')

            .segment('add-card', {
                templateUrl: 'template/settings/tabs/payment/add-card.html',
                controller: 'AddCardCtrl'
            });

        $routeSegmentProvider
            .when('/confirm-billing-address', 'confirm-billing-address')

            .segment('confirm-billing-address', {
                templateUrl: 'template/dashboard/verification/confirm-billing-address.html',
                controller: 'ConfirmBillingAddressCtrl'
            });

        $routeSegmentProvider
            .when('/upload-verification-document', 'upload-verification-document')

            .segment('upload-verification-document', {
                templateUrl: 'template/dashboard/verification/upload-verification-document.html',
                controller: 'UploadVerificationDocumentCtrl'
            });

        $routeSegmentProvider
            .when('/insurance-verification', 'insurance-verification')

            .segment('insurance-verification', {
                templateUrl: 'template/dashboard/verification/insurance/insurance-verification.html',
                controller: 'InsuranceVerificationCtrl'
            });

        $routeSegmentProvider
            .when('/upload-insurance-verification', 'upload-insurance-verification')

            .segment('upload-insurance-verification', {
                templateUrl: 'template/settings/tabs/insurance/upload-insurance.html',
                controller: 'UploadInsuranceVerificationCtrl'
            });

        $routeSegmentProvider
            .when('/upload-insurance', 'upload-insurance')

            .segment('upload-insurance', {
                templateUrl: 'template/settings/tabs/insurance/upload-insurance.html',
                controller: 'UploadInsuranceCtrl'
            });

        $routeSegmentProvider
            .when('/experience-verification', 'experience-verification')

            .segment('experience-verification', {
                templateUrl: 'template/dashboard/verification/experience-verification.html',
                controller: 'ExperienceVerificationCtrl'
            });

        $routeSegmentProvider
            .when('/bike-shop-experience', 'bike-shop-experience')

            .segment('bike-shop-experience', {
                templateUrl: 'template/dashboard/verification/bike-shop-experience.html',
                controller: 'BikeShopExperienceCtrl'
            });

        $routeSegmentProvider
            .when('/profile-verification', 'profile-verification')

            .segment('profile-verification', {
                templateUrl: 'template/dashboard/verification/profile-verification.html',
                controller: 'ProfileVerificationCtrl'
            });

        $routeSegmentProvider
            .when('/profile-picture-verification', 'profile-picture-verification')

            .segment('profile-picture-verification', {
                templateUrl: 'template/dashboard/verification/profile-picture-verification.html',
                controller: 'ProfilePictureCtrl'
            });

        $routeSegmentProvider
            .when('/choose-tools', 'choose-tools')

            .segment('choose-tools', {
                templateUrl: 'template/dashboard/verification/choose-tools.html',
                controller: 'ChooseToolsCtrl'
            });

        $routeSegmentProvider
            .when('/biography-verification', 'biography-verification')

            .segment('biography-verification', {
                templateUrl: 'template/dashboard/verification/biography-verification.html',
                controller: 'BiographyVerificationCtrl'
            });

        $routeSegmentProvider
            .when('/admin/statistic', 'admin-statistic')
            .when('/admin/statistic/users', 'admin-statistic.users')
            .when('/admin/statistic/bikes', 'admin-statistic.bikes')
            .when('/admin/statistic/requests', 'admin-statistic.requests')
            .segment('admin-statistic', {
                templateUrl: 'template/admin/statistic/general-statistic.html',
                controller: 'GeneralStatisticsCtrl'
            })
            .within()
            .segment('users', {
                templateUrl: 'template/admin/statistic/general/users-statistic.html',
                controller: 'UsersStatisticsCtrl'
            }).segment('bikes', {
                templateUrl: 'template/admin/statistic/general/bikes-statistic.html',
                controller: 'BikesStatisticsCtrl'
            }).segment('requests', {
                templateUrl: 'template/admin/statistic/general/requests-statistic.html',
                controller: 'RequestsStatisticsCtrl'
            });

        $routeSegmentProvider
            .when('/request', 'request')
            .when('/request/:id', 'request.show')
            .when('/request/:id/history', 'request.history')

            .segment('request', {
                templateUrl: 'components/request/view/request.html'
            })
            .within()
            .segment('show', {
                templateUrl: 'components/request/view/show-request.html',
                controller: 'ShowRequestCtrl'
            })
            .segment('history', {
                templateUrl: 'components/request/view/show-request.html',
                controller: 'ShowRequestCtrl'
            });

        $routeSegmentProvider
            .when('/admin/view-tool/:id', 'admin-view-tool')
            .when('/admin/create-tool', 'admin-create-tool')
            .when('/admin/tools', 'admin-tools-list')

            .segment('admin-tools-list', {
                templateUrl: 'components/tool/template/tools-list.html',
                controller: 'ToolListCtrl'
            })
            .segment('admin-view-tool', {
                templateUrl: 'components/tool/template/view-tool.html',
                controller: 'ViewToolCtrl'
            })
            .segment('admin-create-tool', {
                templateUrl: 'components/tool/template/create-tool.html',
                controller: 'CreateToolCtrl'
            });

        $routeSegmentProvider
            .when('/admin/requests', 'admin-requests')

            .segment('admin-requests', {
                templateUrl: 'components/request/list/admin-request-list.html',
                controller: 'AdminRequestListCtrl'
            });

        $routeSegmentProvider
            .when('/admin/bikes', 'admin-bikes')

            .segment('admin-bikes', {
                templateUrl: 'components/bike/list/admin/admin-bike-list.html',
                controller: 'BusinessBikeListController'
            });

        $routeSegmentProvider
            .when('/admin/service-management', 'admin-sm')
            .when('/admin/service-management/services', 'admin-sm.services')
            .when('/admin/service-management/categories', 'admin-sm.service-categories')
            .when('/admin/service-management/service-buckets', 'admin-sm.service-buckets')
            .when('/admin/view-service/:id', 'admin-view-service')
            .when('/admin/create-service', 'admin-create-service')
            .when('/admin/create-category', 'admin-create-category')
            .when('/admin/create-service-bucket', 'admin-create-service-bucket')
            .when('/admin/view-category/:id', 'admin-view-category')
            .when('/admin/view-bucket/:id', 'admin-view-bucket')

            .segment('admin-sm', {
                templateUrl: 'components/service/core-service-management.html',
                controller: 'CoreServiceManagementCtrl'
            })
            .within()
            .segment('services', {
                templateUrl: 'components/service/list/core-service-list.html',
                controller: 'CoreServiceListCtrl'
            })
            .segment('service-categories', {
                templateUrl: 'components/service/list/core-service-category-list.html',
                controller: 'CoreServiceCategoryListCtrl'
            })
            .segment('service-buckets', {
                templateUrl: 'components/service/list/core-service-buckets-list.html',
                controller: 'CoreServiceBucketsListCtrl'
            })
            .up()

            .segment('admin-view-service', {
                templateUrl: 'components/service/view/core-service-view.html',
                controller: 'CoreServiceViewCtrl'
            })
            .segment('admin-create-service', {
                templateUrl: 'components/service/create/create-core-service.html',
                controller: 'CreateCoreServiceCtrl'
            })
            .segment('admin-view-category', {
                templateUrl: 'components/service/view/core-service-category-view.html',
                controller: 'CoreServiceCategoryViewCtrl'
            })
            .segment('admin-create-category', {
                templateUrl: 'components/service/create/create-core-service-category.html',
                controller: 'CreateCoreServiceCategoryCtrl'
            })
            .segment('admin-view-bucket', {
                templateUrl: 'components/service/view/core-service-buckets-view.html',
                controller: 'CoreServiceBucketsViewCtrl'
            })
            .segment('admin-create-service-bucket', {
                templateUrl: 'components/service/create/create-core-service-bucket.html',
                controller: 'CreateCoreServiceBucketCtrl'
            });

        $routeSegmentProvider
            .when('/admin/service-flow', 'admin-service-flow')
            .when('/admin/service-flow/create', 'admin-service-flow-create')
            .when('/admin/service-flow/:id', 'admin-service-flow-view')

            .segment('admin-service-flow', {
                templateUrl: 'template/admin/flow-description/task-flow-descriptions.html',
                controller: 'TaskFlowDescriptionsCtrl'
            })
            .segment('admin-service-flow-view', {
                templateUrl: 'template/admin/flow-description/add-update-task-flow-description.html',
                controller: 'AddUpdateTaskFlowDescriptionsCtrl'
            })
            .segment('admin-service-flow-create', {
                templateUrl: 'template/admin/flow-description/add-update-task-flow-description.html',
                controller: 'AddUpdateTaskFlowDescriptionsCtrl'
            });

        $routeSegmentProvider
            .when('/create-request', 'create-request')

            .segment('create-request', {
                templateUrl: 'components/request/create/base-create-request.html',
                controller: 'BaseCreateRequestController',
                access: {requireLogin: false}
            });

        $routeSegmentProvider
            .when('/user', 'user')
            .when('/user/:id', 'user.show')

            .segment('user', {
                templateUrl: 'components/request/view/user.html'
            })
            .within().segment('show', {
            templateUrl: 'components/request/view/show-user.html',
            controller: 'ShowUserCtrl'
        });

        $routeSegmentProvider
            .when('/admin/realtime-statistics', 'admin-realtime-statistics')

            .segment('admin-realtime-statistics', {
                templateUrl: 'template/admin/statistic/general/realtime-statistics.html',
                controller: 'RealtimeStatisticsCtrl'
            });

        $routeSegmentProvider
            .when('/mechanic-public/:id', 'view')

            .segment('view', {
                templateUrl: 'template/profile/mechanic/mechanic-profile-view.html',
                controller: 'MechanicProfileViewCtrl',
                access: {requireLogin: false}
            });

        $routeSegmentProvider
            .when('/admin/analytics', 'admin-analytics')
            .when('/admin/analytics/uncoated-areas', 'admin-analytics.uncoated-areas')
            .when('/admin/analytics/uncoated-areas/:id', 'admin-analytics.uncoated-area')

            .segment('admin-analytics', {
                templateUrl: 'components/analytics/template/analytics.html',
                controller: 'AnalyticsCtrl'
            })
            .within()
            .segment('uncoated-areas', {
                templateUrl: 'components/analytics/template/uncoated-area-list.html',
                controller: 'UncoatedAreaListCtrl'
            })
            .up()
            .within()
            .segment('uncoated-area', {
                templateUrl: 'components/analytics/template/view-uncoated-area.html',
                controller: 'ViewUncoatedAreaCtrl'
            })
            .up();

        $routeSegmentProvider
            .when('/maintenance', 'maintenance')

            .segment('maintenance', {
                templateUrl: 'components/error-pages/maintenance/maintenance-page.html',
                controller: 'MaintenancePageController',
                access: {requireLogin: false}
            });

        $routeSegmentProvider
            .when('/services', 'services')

            .segment('services', {
                templateUrl: 'components/service/list/personal-service-list.html',
                controller: 'PersonalServiceListCtrl'
            });

        $routeSegmentProvider
            .when('/corporate-services', 'corporate-services')

            .segment('corporate-services', {
                templateUrl: 'components/service/list/corporate-service-list.html',
                controller: 'CorporateServiceListCtrl'
            });

        $routeProvider.otherwise({redirectTo: '/home'});
    }
]);
