import './manage-service.modal.scss';

export default function ManageServiceModal($scope, $q, close, services) {
  'ngInject';

  $scope.manageService = {
    typeOfService: 'NEW',
    services: services
  };

  $scope.ok = (form) => {
    if (form.$valid) {
      const result = {
        typeOfService: $scope.manageService.typeOfService,
        service: $scope.manageService.service,
        serviceId: $scope.manageService.serviceId,
        price: $scope.manageService.price,
        duration: $scope.manageService.duration,
        description: $scope.manageService.description
      }

      close(result);
    }
  };

  $scope.mapServiceValue = value => {
    const foundService = $scope.manageService.services.find(service => service.id === value);
    return foundService ? foundService.name : value;
  }

  $scope.onChangeService = value => $scope.manageService.serviceId = value;

  $scope.close = () => {
    close();
  };

}