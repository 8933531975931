import app from "js/legacy-app";

app.service('Security', ['AuthService', '$location', function (AuthService, $location) {

  return {
    check: function (page, route) {
      const pathRootPart = page.split('/')[1];
      let path = '/';
      if (pathRootPart) {
        path += pathRootPart;
      }

      switch (path) {
        case '/':
        case '/home':
        case '/sign-up-user':
        case '/become-a-mechanic':
        case '/sign-up-mechanic':
        case '/login':
          if (AuthService.isFullyLoggedIn()) {
            AuthService.currentUser(user => {
              if (AuthService.isUser()) {
                if (AuthService.isActivatedUser()) {
                  $location.path('/bicycle-owner/service-bookings');
                } else {
                  $location.path('/bicycle-owner/complete-registration');
                }
              } else if (AuthService.isBikeOrganisationOwner()) {
                $location.path('/organisations');
              } else {
                $location.path('/dashboard');
              }
            })
          }
          break;
        case '/dashboard':
          AuthService.currentUser(user => {
            if (AuthService.isUser()) {
              if (AuthService.isActivatedUser()) {
                $location.path('/bicycle-owner/service-bookings');
              } else {
                $location.path('/bicycle-owner/complete-registration');
              }
            } else if (AuthService.isBikeOrganisationOwner()) {
              $location.path('/organisations');
            }
          })
          break;
        case '/request':
          if (!AuthService.isFullyLoggedIn()) {
            $location.path('/login');
          } else {
            AuthService.currentUser(user => {
              if (AuthService.isUser()) {
                if (AuthService.isActivatedUser()) {
                  $location.path('/bicycle-owner/service-bookings/' + route.id);
                } else {
                  $location.path('/bicycle-owner/complete-registration');
                }
              }
            });
          }
          break;
        case '/bike':
          AuthService.currentUser(user => {
            if (AuthService.isUser()) {
              if (AuthService.isActivatedUser()) {
                $location.path('/bicycle-owner/bicycles/' + route.bikeId);
              } else {
                $location.path('/bicycle-owner/complete-registration');
              }
            } else if (AuthService.isBikeOrganisationOwner()) {
              $location.path('/organisations');
            }
          });
          break;
        case '/company-dashboard':
          $location.path('/organisations');
          break;
        case '/pass-quiz':
        case '/experience-verification':
        case '/bike-shop-experience':
        case '/profile-verification':
        case '/profile-picture-verification':
        case '/biography-verification':
        case '/upload-verification-document':
        case '/confirm-billing-address':
        case '/choose-tools':
          if (!AuthService.isNotVerifiedMechanic()) {
            $location.path('/dashboard');
          }
          break;
        case '/upload-insurance-verification':
        case '/settings':
          AuthService.currentUser(user => {
            if (AuthService.isUser()) {
              $location.path('/bicycle-owner/settings');
            } else if (AuthService.isBikeOrganisationOwner()) {
              $location.path('/organisations');
            }
          });
          break;
        case '/settings/payment/connected-account/stripe':
        case '/work-area':
        case '/mechanic-settings':
          if (!AuthService.isMechanic()) {
            $location.path('/dashboard');
          }
          break;
        case '/admin':
          if (!AuthService.isAdmin()) {
            $location.path('/dashboard');
          }
          break;
        case '/complete-social-registration':
          if (!AuthService.isPartiallyRegistered()) {
            $location.path('/');
          }
          break;
        case '/create-request': {
          if (!AuthService.isFullyLoggedIn() || AuthService.isMechanic() || AuthService.isAdmin()) {
            $location.path('/dashboard');
          } else {
            AuthService.currentUser(user => {
              if (AuthService.isUser()) {
                if (AuthService.isActivatedUser()) {
                  $location.path('/bicycle-owner/book-service');
                } else {
                  $location.path('/bicycle-owner/complete-registration');
                }
              } else if (AuthService.isBikeOrganisationOwner()) {
                $location.path('/organisations');
              }
            });
          }
          break;
        }
        case '/partner': {
          if (page.match(/^\/partner\/.+\/book-service$/)) {
            break;
          }

          if (!AuthService.isBusinessAccount()) {
            $location.path('/dashboard');
          }
          break;
        }
        case '/mechanic': {
          if (!AuthService.isMechanic()) {
            $location.path('/dashboard');
          }
          break;
        }
        case '/mechanics': {
          if (!AuthService.isFullyLoggedIn()) {
            $location.path('/login');
          } else {
            AuthService.currentUser(user => {
              if (AuthService.isUser()) {
                if (AuthService.isActivatedUser()) {
                  $location.path('/bicycle-owner/service-providers/search');
                } else {
                  $location.path('/bicycle-owner/complete-registration');
                }
              } else if (AuthService.isBikeOrganisationOwner()) {
                $location.path('/organisations');
              }
            });
          }
          break;
        }

        case '/bicycle-owner': {
          switch (page) {
            case '/bicycle-owner/book-service':
            case '/bicycle-owner/service-bookings':
            case '/bicycle-owner/bicycles':
            case '/bicycle-owner/notifications':
            case '/bicycle-owner/service-providers/favorite':
            case '/bicycle-owner/service-providers/search':
            case '/bicycle-owner/settings/payments':
            case '/bicycle-owner/settings/transactions': {
              if (AuthService.isUser()) {
                if (!AuthService.isActivatedUser()) {
                  $location.path('/bicycle-owner/complete-registration');
                }
              } else if (AuthService.isBikeOrganisationOwner()) {
                $location.path('/organisations');
              }
            }
          }
          break;
        }
        case '/select-organisation':
        case '/organisations':
        case '/organisation': {
          if (!AuthService.isBusinessAccount()) {
            $location.path('/dashboard');
          }
          break;
        }
      }

      if (page.match(/\/dashboard\/agreement\/*/) && !AuthService.isMechanic()) {
        $location.path('/dashboard');
      }

      if (page.match(/\/dashboard\/admin\/*/) && !AuthService.isAdmin()) {
        $location.path('/dashboard');
      }
    }
  };
}]);
