export default function SideFormServiceProvider() {
  this.$get = () => {
    'ngInject';

    function SideFormService() {

      const self = this;
      const forms = {};

      self.register = (id, form) => {
        forms[id] = form;
      }

      self.unregister = (id, form) => {
        delete forms[id];
      }

      self.open = (id) => {
        if (forms[id]) {
          forms[id].open();
        } else {
          console.log('form ' + id + ' has not registered');
        }
      }

      self.close = id => {
        if (forms[id]) {
          forms[id].close();
        } else {
          console.log('form ' + id + ' has not registered');
        }
      }
    }

    return new SideFormService();
  };
}