export default function ($routeSegmentProvider) {

  "ngInject";

  $routeSegmentProvider
    .when('/organisation/:orgId/notifications', 'organisation.notifications')

    .within('organisation')

    .segment('notifications', {
      access: {requireLogin: true},
      breadcrumb: [{
        label: 'organisation_owner.breadcrumb.notifications'
      }],
      template: '<organisation-owner-notifications-list-page></organisation-owner-notifications-list-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "organisation-owner-notifications-list.module" */ "./list/organisation-owner-notifications-list.module");
      }
    })
}
