import angular from "angular";

import VtSharedModule from '../../../vt-shared/vt-shared.module';
import OrganisationOwnerMenuComponents from "../../components/menu/menu.module";
import OrganisationOwnerModuleRouting from './organisation-owner-root.module.routing';
import OrganisationOwnerRootComponent from "./organisation-owner-root.component";
import OrganisationOwnerContactUsApi from '../../resources/contact-us/organisation-owner-contct-us.resource';

export default angular.module("vt-organisation-owner-root.module", [
  VtSharedModule.name,
  OrganisationOwnerMenuComponents.name,
  OrganisationOwnerContactUsApi.name
])
  .config(OrganisationOwnerModuleRouting)
  .component("organisationOwnerPage", OrganisationOwnerRootComponent);
