import './top-up-balance.modal.scss';

export default function TopUpBalanceModal($scope, close, sources, currentSource) {
  'ngInject';

  $scope.topUpBalance = {
    amount: 100,
    sources: sources || [],
    currentSource: currentSource
  };

  $scope.close = () => {
    close();
  };

  $scope.ok = () => {
    close({
      paymentSource: $scope.topUpBalance.currentSource,
      amount: $scope.topUpBalance.amount,
      currency: "USD"
    });
  };

  $scope.addNewCard = () => {
    close({ new: true });
  };

}