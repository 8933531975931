import LoadingImage from "img/loading-bicycle.gif";

import './upload-images.modal.scss';

export default function UploadImagesModal($scope, $element, close, images, title, onUpload, FileService, $timeout, Notification, $t) {
  'ngInject';

  $scope.uploadImages = {
    images: structuredClone(images),
    title: title,
    hideFirst: true,
    size: 'xl'
  };

  $scope.chooseFile = function (files, invalidFiles) {
    if (!files || files.length === 0) {
      return;
    }

    const filesToUpload = [];
    files.filter(file => FileService.isValidFile(file))
      .forEach(file => filesToUpload.push(file));
    invalidFiles.filter(file => FileService.isValidFile(file))
      .forEach(file => filesToUpload.push(file));
    if (filesToUpload.length === 0) {
      return;
    }

    filesToUpload.forEach(file => {
      const img = {
        id: file.name,
        thumbnail: LoadingImage,
        original: file,
        uploading: true,
        error: true
      }

      $scope.uploadImages.images.push(img);
      $scope.uploadImages.upload($scope.uploadImages.images.length - 1)
    })

    $scope.uploadImages.recalculate();
  };

  $scope.uploadImages.upload = index => {
    const img = $scope.uploadImages.images[index];
    img.uploading = true;
    img.error = false;

    onUpload(img.original,
      response => {
        for (let i = 0; i < $scope.uploadImages.images.length; i++) {
          if ($scope.uploadImages.images[i].id === img.id) {
            $scope.uploadImages.images[i] = response;
            break;
          }
        }
      },
      error => {
        img.error = true;
        img.uploading = false;
        Notification.error(error.data.message || $t('shared.modals.photos.upload_image_error'))
      }
    );
  }

  $scope.uploadImages.remove = (index) => {
    if (index > -1 && index < $scope.uploadImages.images.length) {
      $scope.uploadImages.images.splice(index, 1)
    }
  }

  $scope.uploadImages.makeDefault = (index) => {
    if (index > -1 && index < $scope.uploadImages.images.length) {
      const tmp = $scope.uploadImages.images[index];
      $scope.uploadImages.images[index] = $scope.uploadImages.images[0];
      $scope.uploadImages.images[0] = tmp;
    }
  }

  $scope.save = () => {
    const result = [];
    $scope.uploadImages.images.forEach(img => {
      if (!img.error && !img.uploading) {
        result.push(img);
      }
    })

    close(result);
  }

  $scope.close = () => {
    close(false);
  }
}