import angular from "angular";

import './location-filter.component.scss';

export default () => {
  return {
    restrict: 'E',
    replace: true,
    require: ['ngModel'],
    scope: {
      ngModel: "=ngModel",
      placeholder: "@vuiPlaceholder",
      filterPlaceholder: "@vuiFilterPlaceholder",
      name: "@vuiName",
      clazz: "@ngClass",
      onChange: '=vuiOnChange',
      locationResource: '<vuiLocationResource',
    },
    transclude: true,
    template: require('./location-filter.component.html'),
    link: (scope, element) => {

      const $input = angular.element(element.find('input')[0]);
      const $filter = angular.element(element.find('input')[1]);

      const findElemByClass = (className) => {
        const list = element.find('div')
        for (let i = 0; i < list.length; i++) {
          if (list[i].className.indexOf(className) >= 0) {
            return angular.element(list[i]);
          }
        }
      }

      const dropdown = findElemByClass('vui-select__dropdown')

      const open = () => {
        element.addClass('vui-input-container--opened');
        const rect = element[0].getClientRects()[0];

        const marginTop = calculateDropdownMargin(rect.bottom + dropdown[0].offsetHeight > window.innerHeight, dropdown[0])
        dropdown.css('margin-top', marginTop + 'px');
        dropdown.css('width', rect.width + 'px');

        $filter.focus()
      }

      const calculateDropdownMargin = (isBottom, dropdown) => {
        return isBottom ? -(dropdown.offsetHeight + element[0].offsetHeight + 8) : 8;
      }

      scope.onChangeLocation = newValue => {
        scope.setModel(newValue)
        if (scope.onChange) {
          scope.onChange(scope.ngModel);
        }
      }

      scope.setModel = (model) => {
        scope.ngModel = model;
        if (scope.onChange) {
          scope.onChange(scope.ngModel);
        }
      }

      scope.modelToString = () => {
        if (scope.ngModel) {
          return scope.ngModel.address;
        }
      }

      scope.toggle = () => {
        if (element.hasClass('vui-input-container--opened')) {
          scope.close();
        } else {
          open();
        }
      }

      scope.close = () => {
        element.removeClass('vui-input-container--opened')
      }

      $input.on('focusin', (e) => scope.toggle());
      $input.on('keydown', function (e) {
        const keyCode = e.which || e.keyCode;
        if (keyCode === 40) {
          dropdown.find('div')[0].focus();
          e.preventDefault();
        } else if (keyCode === 38) {
          const options = dropdown.find('div');
          options[options.length - 1].focus();
          e.preventDefault();
        } else if (keyCode === 27) {
          scope.close();
        } else {
          e.preventDefault();
        }
      });
    }
  };
}
