export default function ($routeSegmentProvider) {

  "ngInject";

  $routeSegmentProvider
    .when('/bicycle-owner/settings/payments', 'bicycle-owner.payments')
    .when('/bicycle-owner/settings/transactions', 'bicycle-owner.transactions')
    .when('/bicycle-owner/settings/transactions/:id', 'bicycle-owner.transaction-info')

    .within('bicycle-owner')

    .segment('payments', {
      access: {requireLogin: true},
      breadcrumb: [{
        label: 'bicycle_owner.breadcrumb.settings'
      }],
      template: '<bicycle-owner-payments-page></bicycle-owner-payments-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "bicycle-owner-payments.module" */ "./payments/bicycle-owner-payments.module");
      }
    })

    .segment('transactions', {
      access: {requireLogin: true},
      breadcrumb: [{
        label: 'bicycle_owner.breadcrumb.settings'
      }],
      template: '<bicycle-owner-transactions-page></bicycle-owner-transactions-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "bicycle-owner-transactions.module" */ "./transactions/bicycle-owner-transactions.module");
      }
    })

    .segment('transaction-info', {
      access: {requireLogin: true},
      breadcrumb: [
        {
          label: 'bicycle_owner.breadcrumb.settings',
          url: '/bicycle-owner/profile/transactions'
        },
        {
          label: 'bicycle_owner.breadcrumb.transaction_details'
        }
      ],
      template: '<bicycle-owner-transaction-info-page></bicycle-owner-transaction-info-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "bicycle-owner-transaction-info.module" */ "./transaction-info/bicycle-owner-transaction-info.module");
      }
    });
}
