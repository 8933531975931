import angular from "angular";

import './stepper.component.scss';

export default {
  template: require('./stepper.component.html'),
  transclude: {
    steps: "?vuiStepperSteps",
    contents: "?vuiStepperContents"
  },
  bindings: {
    stepCount: "<vuiStepCount",
    stepper: "=vuiStepper"
  },
  controller: function ($element, $timeout, $document, $window, $handlers) {
    'ngInject';

    const self = this;

    $timeout(() => {
      const steps = $element.children().children()[0].children[0].children;
      self.stepCount = steps.length;
      self.steps = [];
      steps.forEach(step => self.steps.push(angular.element(step.children[0])));

      const spacers = [];
      for (let i = 0; i < this.stepCount - 1; i++) {
        spacers.push(i);
      }
      self.spacers = spacers;

      const contents = angular.element($element.children().children()[2]).children().children();
      const contentList = [];
      for (let i = 0; i < contents.length; i++) {
        contentList.push(angular.element(contents[i]).children())
      }
      self.contents = contentList;

      self.content = $element.children().children()[2].children[0];
      self.stepContent = self.content.children[0];

      self.stepper.slide();
    });

    self.stepper = {
      current: 0,
      next: () => {
        if (self.stepper.current < self.stepCount - 1) {
          self.stepper.current++
        }

        self.stepper.slide();
      },
      prev: () => {
        if (self.stepper.current > 0) {
          self.stepper.current--;
        }

        self.stepper.slide();
      },
      setStep: step => {
        self.stepper.current = step;
        self.stepper.slide();
      },
      slide: (skipScroll) => {
        self.content.style.left = -(self.stepper.current * self.stepContent.getBoundingClientRect().width) + 'px';
        $timeout(() => self.content.style.left = -(self.stepper.current * self.stepContent.getBoundingClientRect().width) + 'px', 260);
        changeStepsState()
        if (!skipScroll) {
          scrollTo(document.querySelector('.vui-content'), 0, 250);
        }
      }
    };

    const scrollTo = (element, to, duration) => {
      if (duration <= 0) return;
      const difference = to - element.scrollTop;
      const perTick = difference / duration * 10;

      setTimeout(function () {
        element.scrollTop = element.scrollTop + perTick;
        if (element.scrollTop === to) {
          return;
        }
        scrollTo(element, to, duration - 10);
      }, 10);
    }

    const changeStepsState = () => {
      for (let i = 0; i < self.steps.length; i++) {
        const step = self.steps[i];
        const content = self.contents[i];

        if (i < self.stepper.current) {
          step.removeClass('active');
          step.addClass('completed');
          $timeout(() => content.removeClass('active'), 250);
        } else if (i === self.stepper.current) {
          step.addClass('active');
          step.removeClass('completed');
          content.addClass('active');
        } else {
          step.removeClass('active');
          step.removeClass('completed');
          $timeout(() => content.removeClass('active'), 250);
        }
      }
    }

    const onResize = () => {
      self.stepper.slide(true);
    }

    $handlers.subscribe($handlers.TOGGLE_SIDE_MENU, onResize);

    $document.on('resize', onResize);
    angular.element($window).bind('resize', onResize);
    $document.on('$destroy', () => {
      $document.off('resize', onResize);
      angular.element($window).unbind('resize', onResize);
      $handlers.unsubscribe($handlers.TOGGLE_SIDE_MENU, onResize);
    });

  }
};
