export default function ($routeSegmentProvider) {

  "ngInject";

  $routeSegmentProvider
    .when('/organisations', 'organisations')
    .when('/select-organisation/:orgId', 'select-organisation')

    .segment('organisations', {
      access: {requireLogin: true},
      template: '<organisation-owner-all-organisations-page></organisation-owner-all-organisations-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "organisation-owner-all-organisations.module" */ "./all/organisation-owner-all-organisations.module");
      }
    })
    .segment('select-organisation', {
      access: {requireLogin: true},
      template: '<organisation-owner-select-organisation-page></organisation-owner-select-organisation-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "organisation-owner-select-organisation.module" */ "./select/organisation-owner-select-organisation.module");
      }
    });
}
