export default function HandlersServiceProvider() {
  this.$get = () => {
    'ngInject';

    function HandlersService() {

      const self = this;
      self.TOGGLE_SIDE_MENU = 'toggle-side-menu';

      const handlers = {};

      self.register = (id) => {
        handlers[id] = [];
      }

      self.unregister = (id) => {
        delete handlers[id];
      }

      self.subscribe = (id, handler) => {
        if (handlers[id]) {
          handlers[id].push(handler);
        } else {
          console.log('handler ' + id + ' has not registered');
        }
      }

      self.unsubscribe = (id, handler) => {
        if (handlers[id]) {
          const index = handlers[id].indexOf(5);
          if (index > -1) {
            handlers[id].splice(index, 1);
          }
        } else {
          console.log('handler ' + id + ' has not registered');
        }
      }

      self.call = (id, params) => {
        if (handlers[id]) {
          handlers[id].forEach(handler => handler((params)));
        } else {
          console.log('handler ' + id + ' has not registered');
        }
      }
    }

    return new HandlersService();
  };
}