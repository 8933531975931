import "./content-view.component.scss";

export default {
  template: require('./content-view.component.html'),
  transclude: {
    header: "?vuiListViewHeader",
    content: "?vuiListViewContent",
    footer: "?vuiListViewFooter",
    noContent: "?vuiListViewNoContent"
  },
  bindings: {
    hasContent: "<?vuiHasContent",
    withPagination: "<?vuiWithPagination",
    withFilters: "<?vuiWithFilters",
    tabs: "<?vuiTabs"
  },
  controller: function () {

    'ngInject';

    const self = this;

    if (self.hasContent === undefined) {
      self.hasContent = true;
    }

    if (self.withPagination === undefined) {
      self.withPagination = true;
    }

    if (self.withFilters === undefined) {
      self.withFilters = true;
    }

    if (self.tabs === undefined) {
      self.tabs = false;
    }
  }
};
