export default function ($routeSegmentProvider) {

  "ngInject";

  $routeSegmentProvider
    .when('/organisation/:orgId/book-service', 'organisation.book-service')
    .when('/organisation/:orgId/service-bookings', 'organisation.service-bookings')
    .when('/organisation/:orgId/service-bookings/:id', 'organisation.service-booking-info')
    .when('/organisation/:orgId/service-bookings/:id/reassign', 'organisation.reassign-service-booking')

    .within('organisation')

    .segment('book-service', {
      access: {requireLogin: true},
      breadcrumb: [
        {
          label: 'organisation_owner.breadcrumb.services',
          url: '/organisation/:orgId/service-bookings'
        },
        {
          label: 'organisation_owner.breadcrumb.service_booking'
        }
      ],
      template: '<organisation-owner-book-service-page></organisation-owner-book-service-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "organisation-owner-book-service.module" */ "./book-service/organisation-owner-book-service.module");
      }
    })
    .segment('service-bookings', {
      access: {requireLogin: true},
      breadcrumb: [{
        label: 'organisation_owner.breadcrumb.services'
      }],
      template: '<organisation-owner-requests-list-page></organisation-owner-requests-list-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "organisation-owner-requests-list.module" */ "./list/organisation-owner-requests-list.module");
      }
    })
    .segment('service-booking-info', {
      access: {requireLogin: true},
      breadcrumb: [
        {
          label: 'organisation_owner.breadcrumb.services',
          url: '/organisation/:orgId/service-bookings'
        },
        {
          label: 'bicycle_owner.breadcrumb.service_booking'
        }
      ],
      template: '<organisation-owner-request-info-page></organisation-owner-request-info-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "organisation-owner-request-info.module" */ "./info/organisation-owner-request-info.module");
      }
    })
    .segment('reassign-service-booking', {
      access: {requireLogin: true},
      breadcrumb: [
        {
          label: 'organisation_owner.breadcrumb.services',
          url: '/organisation/:orgId/service-bookings'
        },
        {
          label: 'organisation_owner.breadcrumb.service_booking',
          url: '/organisation/:orgId/service-bookings/:id',
          params: {
            id: ':id'
          }
        },
        {
          label: 'organisation_owner.breadcrumb.reassign_service_booking'
        }
      ],
      template: '<organisation-owner-reassign-request-page></organisation-owner-reassign-request-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "organisation-owner-reassign-request.module" */ "./reassign/organisation-owner-reassign-request.module");
      }
    })
}
