export default function LocalStorageProvider() {
  this.$get = () => {
    'ngInject';

    function LocalStorageProvider() {

      const self = this;

      self.put = (key, value) => {
        localStorage[key] = JSON.stringify(value);
      }

      self.get = (key, defaultValue) => {
        const value = localStorage[key];
        if (value) {
          return JSON.parse(value);
        }

        return defaultValue;
      }

    }

    return new LocalStorageProvider();
  };
}