import './main-menu-organisations-item.component.scss';
import DefaultImage from "img/ic_no_avatar.svg";

export default {
  template: require('./main-menu-organisations-item.component.html'),
  controller: function ($routeParams, $t, ToggleLD, Notification, AuthService, OrganisationOwnerOrganisationsApi) {
    'ngInject';

    const self = this;

    self.auth = AuthService;
    self.actions = {};

    self.onSelectOrganisation = item => {
      self.actions.close();
    }

    self.getFullName = () => {
      if (AuthService.user()) {
        return AuthService.user().name + ' ' + AuthService.user().surname;
      } else {
        return ''
      }
    }

    self.getOrgImage = org => {
      if (org && org.image) {
        return org.image.thumbnail;
      }

      return DefaultImage;
    };

    self.loadOrganisation = id => {
      ToggleLD.show();
      OrganisationOwnerOrganisationsApi.getById({id: id},
        response => {
          self.currentOrganisation = response;
          ToggleLD.hide();
        },
        error => Notification.error(error.data.message || $t('organisation_owner.select_organisation.loading_organisation_error_message')));
    }

    self.loadOrganisations = () => {
      ToggleLD.show();
      OrganisationOwnerOrganisationsApi.getAll({page: self.page, size: 5},
        response => {
          response.forEach(item => {
            if (!item.image) {
              item.image = {thumbnail: DefaultImage};
            }
          });
          self.organisations = response;
          ToggleLD.hide();
        },
        error => {
          Notification.error(error.data.message || $t('organisation_owner.select_organisation.loading_organisations_error_message'));
        })
    }

    self.loadOrganisation($routeParams.orgId);
    self.loadOrganisations();
  }
};
