import './import-bicycles-warning.modal.scss';

export default function ImportBicyclesWarningModal($scope, close, bicycles) {
  'ngInject';

  $scope.importBicycles = {
    bicycles: bicycles
  };

  $scope.close = () => {
    close(false);
  }
}