import angular from "angular";

const contactUs = function ($resource, $routeParams) {
  'ngInject';

  return $resource('/api/v1/fo/organisation-owner/:orgId/contact-us', {}, {
    send: {
      method: "POST",
      isArray: false,
      params: {
        orgId: $routeParams.orgId
      }
    }
  })
}

export default angular.module('vt-organisation-owner-resource.contact-us', [])
  .service('OrganisationOwnerContactUsApi', contactUs);
