import angular from "angular";

const organisations = function ($resource) {
  'ngInject';

  return $resource('/api/v1/fo/organisation-owner/organisations/:id', {}, {
    getAll: {
      method: "GET",
      isArray: true
    },
    getById: {
      method: "GET",
      isArray: false
    },
    update: {
      method: "PUT",
      isArray: false
    },
  })
}

export default angular.module('vt-organisation-owner-resource.organisations', [])
  .service('OrganisationOwnerOrganisationsApi', organisations);
