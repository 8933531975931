import "./phone-number.component.scss";
import intlTelInputComponent from "intl-tel-input";

export default {
  template: require('./phone-number.component.html'),
  require: {
    form: '^form'
  },
  bindings: {
    number: '<vuiPnNumber',
    onChange: '&vuiPnOnChange',
    isRequired: '@vuiPnIsRequired',
    showIcon: '@vuiPnShowIcon',
    disabled: '<?vuiDisabled'
  },
  controller: function ($http, $scope, $timeout, $t) {

    'ngInject';

    const self = this;
    let iti;
    const countries = [
      'at', 'au', 'be', 'br', 'ca',
      'ch', 'de', 'dk', 'ee', 'es',
      'fi', 'fr', 'gb', 'gr', 'ie',
      'it', 'lt', 'lu', 'lv', 'my',
      'mx', 'nl', 'no', 'nz', 'pl',
      'pt', 'se', 'si', 'sk', 'us'
    ];

    self.icon = self.showIcon !== 'false'

    self.$onInit = () => {
      $timeout(() => {
        iti = initializeComponent();

        if (self.number) {
          iti.setNumber(self.number);
        }
      });
    };

    let onSubmit = () => {
      self.onChange({phone: self.number, isValid: iti.isValidNumber()});
    };

    let initializeComponent = () => {
      let input = document.querySelector("#vui-phone-number-id");
      let ipInfoToken = process.env.IP_INFO_TOKEN;

      let iti = intlTelInputComponent(input, {
        autoHideDialCode: true,
        nationalMode: false,
        autoPlaceholder: "aggressive",
        initialCountry: "auto",
        formatOnDisplay: true,
        customContainer: 'vui-phone-number__container',
        geoIpLookup: (callback) => {
          $http.get("https://ipinfo.io?token=" + ipInfoToken, () => {}, "jsonp")
            .then((resp) => {
              let countryCode = (resp && resp.data && resp.data.country) ? resp.data.country : "us";
              if (countryCode && countries.indexOf(countryCode.toLowerCase()) >= 0) {
                callback(countryCode);
              } else {
                callback('us');
              }
            }, function () {
              callback('us');
            });
        },
        hiddenInput: "fullNumber",
        onlyCountries: countries,
        placeholderNumberType: "MOBILE",
        preferredCountries: ['us', 'ca'],
        separateDialCode: true
      });

      let errorMsg = document.querySelector("#vui-number-error-msg");

      let errorMap = [
        $t('shared.phone_number.invalid_number_error'),
        $t('shared.phone_number.invalid_country_code_error'),
        $t('shared.phone_number.number_too_short_error'),
        $t('shared.phone_number.number_too_long_error'),
        $t('shared.phone_number.invalid_number_error')
      ];

      let reset = function () {
        input.classList.remove("vui-phone-number__error");
        errorMsg.innerHTML = "";
        self.number = iti.getNumber();
        onSubmit();
      };

      let validate = () => {
        if (input.value.trim() && !iti.isValidNumber()) {
          input.classList.add("vui-phone-number__error");
          let errorCode = iti.getValidationError();
          errorMsg.innerHTML = errorMap[errorCode];
          errorMsg.classList.remove("hide");
        } else if (self.isRequired === 'true' && !input.value.trim()) {
          input.classList.add("vui-phone-number__error");
          errorMsg.innerHTML = $t('shared.phone_number.required_error');
          errorMsg.classList.remove("hide");
        }
      };

      input.addEventListener('blur', function () {
        reset();
        validate();
      });

      input.addEventListener('change', reset);
      input.addEventListener('keyup', reset);

      $scope.$watch('$ctrl.form.$submitted', function (newValue, oldValue) {
        if (newValue) {
          validate();
        }
      });

      return iti;
    }
  }
};
