import './menu.component.scss';

export default {
  template: require('./menu.component.html'),
  transclude: {
    mainMenu: "?vuiMainMenuItems",
    sideMenu: "?vuiSideMenuItems",
    pageContent: "?vuiPageContent"
  },
  controller: function ($pageHeader, $scope, $handlers, $timeout) {
    'ngInject';

    $pageHeader.hide();

    const self = $scope.$ctrl = this;
    self.sideMenuSizeClass = 'vui-side-menu--expanded vui-side-menu--close';

    $handlers.register($handlers.TOGGLE_SIDE_MENU);

    self.onResize = (e) => {
      if (self.sideMenuSizeClass.includes('vui-side-menu--collapsed')) {
        self.sideMenuSizeClass = self.sideMenuSizeClass
          .replace('vui-side-menu--collapsed', 'vui-side-menu--expanded');
      } else {
        self.sideMenuSizeClass = self.sideMenuSizeClass
          .replace('vui-side-menu--expanded', 'vui-side-menu--collapsed');
      }

      $handlers.call($handlers.TOGGLE_SIDE_MENU);
    }

    self.onToggle = (e) => {
      if (self.sideMenuSizeClass.includes('vui-side-menu--close')) {
        self.sideMenuSizeClass = self.sideMenuSizeClass
          .replace('vui-side-menu--close', 'vui-side-menu--open');
      } else {
        self.sideMenuSizeClass = self.sideMenuSizeClass
          .replace('vui-side-menu--open', 'vui-side-menu--close');
      }
    }

    $scope.$on('$locationChangeSuccess', (event, next, current) => {
      self.sideMenuSizeClass = self.sideMenuSizeClass
        .replace('vui-side-menu--open', 'vui-side-menu--close');
    });

    $scope.$on('$destroy', () => {
      $handlers.unregister($handlers.TOGGLE_SIDE_MENU);
    })
  }
};
