import './change-bicycle-group.modal.scss';

export default function ChangeBicycleGroupModal($scope, groups, selectedGroup, close) {
  'ngInject';

  $scope.changeGroup = {
    groups: groups,
    group: selectedGroup ? selectedGroup.id : undefined
  };

  $scope.mapGroupValue = val => {
    if (val) {
      return groups.find(group => group.id === val).name;
    }

    return val;
  }

  $scope.ok = (form) => {
    close({id: $scope.changeGroup.group ? $scope.changeGroup.group : undefined});
  }

  $scope.close = () => {
    close(false);
  }
}