export default function ($routeSegmentProvider) {

  "ngInject";

  $routeSegmentProvider
    .when('/organisation/:orgId/profile/personal-info', 'organisation.personal-info')
    .when('/organisation/:orgId/profile/security', 'organisation.security')

    .within('organisation')

    .segment('personal-info', {
      access: {requireLogin: true},
      breadcrumb: [{
        label: 'organisation_owner.breadcrumb.profile'
      }],
      template: '<organisation-owner-personal-info-page></organisation-owner-personal-info-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "organisation-owner-personal-info.module" */ "./personal-info/organisation-owner-personal-info.module");
      }
    })

    .segment('security', {
      access: {requireLogin: true},
      breadcrumb: [{
        label: 'organisation_owner.breadcrumb.profile'
      }],
      template: '<organisation-owner-security-page></organisation-owner-security-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "organisation-owner-security.module" */ "./security/organisation-owner-security.module");
      }
    });
}
