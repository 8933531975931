import './unsatisfied-with-service.modal.scss';

export default function UnsatisfiedWithServiceModal ($scope, close, contactUs) {
  'ngInject';

  $scope.contactUs = () => {
    contactUs();
    close(false);
  }

  $scope.ok = () => {
    close(true);
  }

  $scope.close = () => {
    close(false);
  }
}