import angular from "angular";

import "oclazyload";
import OrganisationOwnerBicyclesModule from "./modules/bicycles/organisation-owner-bicycles.module";
import OrganisationOwnerNotificationsModule from "./modules/notifications/organisation-owner-notifications.module";
import OrganisationOwnerOrganisationsModule from "./modules/organisations/organisation-owner-organisations.module";
import OrganisationOwnerProfileModule from "./modules/profile/organisation-owner-profile.module";
import OrganisationOwnerRequestsModule from "./modules/requests/organisation-owner-requests.module";
import OrganisationOwnerRootModule from "./modules/root/organisation-owner-root.module";
import OrganisationOwnerSettingsModule from "./modules/settings/organisation-owner-settings.module";
import OrganisationOwnerServiceProvidersModule
  from "./modules/service-providers/organisation-owner-service-providers.module";

export default angular
  .module("vt-organisation-owner.module", [
    OrganisationOwnerOrganisationsModule.name,
    OrganisationOwnerRootModule.name,
    OrganisationOwnerBicyclesModule.name,
    OrganisationOwnerNotificationsModule.name,
    OrganisationOwnerProfileModule.name,
    OrganisationOwnerRequestsModule.name,
    OrganisationOwnerSettingsModule.name,
    OrganisationOwnerServiceProvidersModule.name
  ]);
