export default function ($routeSegmentProvider) {

  "ngInject";

  $routeSegmentProvider
    .when('/organisation/:orgId', 'organisation')

    .segment('organisation', {
      access: {requireLogin: true},
      template: '<organisation-owner-page></organisation-owner-page>',
      loadModule: () => {
        return import(/* webpackChunkName: "organisation-owner-root.module" */ "./organisation-owner-root.module");
      }
    });
}
